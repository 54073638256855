export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register your self"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your free account here"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Registration"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vat"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radius"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to site terms and conditions"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created. Please check your email to verify your account."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Client Group"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppliers"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Orders"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufactor"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Craftsman"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrator"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field is required"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registration"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in to your account"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password ?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No account is associated with this email"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Address"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderdate"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Date"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Notes"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salution"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliveryaddress"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingdetails"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingart"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingarts"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipper"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingdate"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipperdetails"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Features"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery placement"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packageremoval"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of use"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h Service MO.-FR."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h Service SA."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48h Service"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOD Mo.-Fr."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOD Saturday"])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwork delivery Service"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express assembly service"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingmethod"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfair Point"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal shipment"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliverystatus"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save shippingdata"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save address"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingoptions"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection at Depot"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track and Trace for"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippingpositions"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ms."])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unkown"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mr."])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create shipping order"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save address"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My interfaces"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used interfaces"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available interfaces"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start synchronization"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last update"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source value"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status type"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Creation"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturer"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returns"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipped"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not shipped"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save the colllie after adding mapping"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save mapping here"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping for"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track & Trace"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipment"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipments"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently Added"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcel with issue"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returns"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Week"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 Days"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 14 Days"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 Days"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Month"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 3 Month"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 6 Month"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 12 Month"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Prepared"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Exception"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking Number"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier (Reference)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Event"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Info"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Added"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filters applied"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview for the last 14 days"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcel Added"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Carriers (Based on volume)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Orders"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders Today"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earnings Today"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection successfully"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Logs"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label Created"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Article Type"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Overview"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master data"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article name"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Description"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistical data"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Articles"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Project Description Templates"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new Template"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Template"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Name"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Project"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Article Project"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Project"])}
    }
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filter set"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total shipments"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Overview"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Contract"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Name"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid From"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Till"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Active"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Required"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Group"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Contract"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Active Status"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Required Status"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Required"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contract"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Contract"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Details"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as read"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Orders"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Shop"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfillment Status"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Method"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Supplier"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Name"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Supplier Name"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Supplier Orders"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML Template Constructor"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created By"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated By"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Features Value"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders Overview"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 15 Orders"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Details"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptions"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Bundle"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Label"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Labels"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shippers"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Art"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outbound Status"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Shipment"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Hold"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Outbound"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Fullfilment"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Issues"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk Shipping Update"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Overview"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Update Order"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Fulfilled Status"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfilled"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Fulfilled"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Tracking Status"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identcode"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery nr"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ext Reference Id"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Order"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Shipping"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Fulfill Order"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfill Order"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not created"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["open"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Process"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Filters"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Filter"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Filter"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered By"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Shippings"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Overview"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Number"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Category"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Group"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Channel Sku"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collie Data"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Collies"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Collies"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse Articles"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Buffer"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Quantity"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Quantity"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Updated At"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Order"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference ID"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Order Number"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Articles"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Order"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Address"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound Status"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Inbound"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound Id"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Image"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Image"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Note"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Status"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Number"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Order"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update external Order"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Order"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Positions"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Not Required"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No shipping art available"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Images"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All Images"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Images"])},
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier item name"])},
    "articlenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article Number"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Time"])},
    "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Price"])}
  },
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
  "orderpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Positions"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Order"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "addPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Package"])},
  "inbound": {
    "fulfillerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulfiller Id"])},
    "mercInboundNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Inbound Number"])},
    "inboundDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound Date"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "warehouseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse Name"])},
    "inboundDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbound Details"])},
    "addPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Packages"])}
  },
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "StockTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Tranfer"])},
  "createFulfillment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Fulfillment"])},
  "change client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Client"])},
  "selectcurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Currency"])},
  "stocks": {
    "addWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Warehouse"])},
    "saveWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Warehouse"])}
  },
  "invoice": {
    "invoiceBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Builder"])},
    "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Step"])},
    "prevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Step"])},
    "selectCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Customer"])},
    "searchCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Customer"])},
    "clientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Details"])}
  }
}