import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Dashboard from '../views/Dashboard.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/ClientLogin.vue'),
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/reset_password',
    name: 'ForgotPassword',
    component: () => import('../components/ForgotPassword.vue'),
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/myinterfaces',
    name: 'MyInterfaces',
    component: () => import('../views/INTERFACES/Myinterfaces.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/user_option',
    name: 'userOption',
    component: () => import('../views/UserOption.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/myinterface/:id',
    name: 'MyInterfaceDetails',
    component: () => import('../views/INTERFACES/Myinterface.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manage-interface/:id',
    name: 'ManageInterface',
    component: () => import('../views/INTERFACES/ManageInterface.vue'),
    props: true,
  },
  {
    path: '/articles',
    name: 'ArticleOverview',
    component: () => import('../views/PIM/Articles.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/articles/:warehouseId',
    name: 'addWarehouseArticle',
    component: () => import('../views/PIM/Articles.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/outbound/:outboundId',
    name: 'MerchantOutbound',
    component: () => import('../views/SHIP/MerchantOutbound.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/articlefeatures',
    name: 'ArticlefeaturesOverview',
    component: () => import('../views/PIM/ArticleFeatures.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/articles_template/new',
    name: 'ArticleDescriptionTemplateNew',
    component: () => import('../views/PIM/NewArticleDescriptionTemplate.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/articles_template/:id',
    name: 'ArticleDescriptionTemplateEdit',
    component: () => import('../views/PIM/EditArticleTemplate.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/articles_templates',
    name: 'ArticleDescriptionTemplate',
    component: () => import('../views/PIM/ArticleTemplates.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/articles/producers',
    name: 'ProducersOverview',
    component: () => import('../views/PIM/Producers.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/article/:id',
    name: 'ArticleDetails',
    component: () => import('../views/PIM/Article.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/suppliers',
    name: 'SupplierOverview',
    component: () => import('../views/ORM/Suppliers.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/supplier/:id',
    name: 'SupplierDetails',
    component: () => import('../views/ORM/Supplier.vue'),
    meta: {
      requiresAuth: true,
    },
    props: true
  },
  {
    path: '/categories',
    name: 'CategoriesOverview',
    component: () => import('../views/Categories.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/articles/project',
    name: 'ArticleProject',
    component: () => import('../views/ArticleProject.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/articles/project/:id',
    name: 'ArticleProjectDetails',
    component: () => import('../views/ArticleProjectDetails.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orders',
    name: 'OrdersOverview',
    component: () => import('../views/ORM/Orders.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/supplierorders',
    name: 'SupplierordersOverview',
    component: () => import('../views/ORM/Supplierorders.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/jobber',
    name: 'JobberOverview',
    component: () => import('../views/Jobber.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/deliverynotes',
    name: 'DerliveryNotesOverview',
    component: () => import('../views/SHIP/DeliveryNotes.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/deliverynote/:id',
    name: 'DerliveryNote',
    component: () => import('../components/SHIP/DeliveryNote.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/shipping',
    name: 'ShippingOverview',
    component: () => import('../components/SHIP/ShippingOverview.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/order/:id',
    name: 'OrderView',
    component: () => import('../views/ORM/Order.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/shipping/:id',
    name: 'ShippingView',
    component: () => import('../views/SHIP/Shipping.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/shippingart/:id',
    name: 'ShippingArtView',
    component: () => import('../views/SHIP/ShippingArt.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/shippingarts',
    name: 'ShippingArtsOverView',
    component: () => import('../views/SHIP/ShippingArts.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/client/registration',
    name: 'ClientRegistration',
    component: () => import('../views/ClientRegisteration.vue'),
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/verify/:token',
    name: 'VerifyEmail',
    component: () => import('../views/EmailVerification.vue'),
    props: true,
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/verify_auth/:token',
    name: 'RedirectAuth',
    component: () => import('../views/ClientLogin.vue'),
    props: true,
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/external/verify/:token',
    name: 'ExternalVerifyEmail',
    component: () => import('../views/ExternalEmailVerification.vue'),
    props: true,
    meta: {
      tokenNotAvailable: true,
    }
  },
  {
    path: '/logout',
    name: 'UserLogout',
    component: () => import('../views/Logout.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/health_check',
    name: 'HealthCheck',
    component: () => import('../views/MicroservicesHealth.vue'),
  },
  {
    path: '/contract',
    name: 'Contract',
    component: () => import('../views/Contracts/Contracts.vue'),
  },
  {
    path: '/contract/:id',
    name: 'ContractDetail',
    component: () => import('../views/Contracts/ContractDetail.vue'),
    props: true,
  },
  {
    path: '/contract/document/:contractId',
    name: 'ContractDocument',
    component: () => import('../views/Contracts/ContractDocument.vue'),
    props: true,
  },
  {
    path: '/contract/document_detail/:docId',
    name: 'ContractDocumentDetail',
    component: () => import('../views/Contracts/ContractDocumentDetail.vue'),
    props: true,
  },
  {
    path: '/public_images',
    name: 'PublicImage',
    component: () => import('../views/PublicImage.vue'),
  },
  {
    path: '/client_registration_fwf_new',
    name: 'RegisterClient',
    component: () => import('../views/RegisterClient.vue'),
  },
  {
    path: '/health_check/phpinfo',
    name: 'PhpInfo',
    component: () => import('../views/PhpInfo.vue'),
  },
  {
    path: '/microservice/:url',
    name: 'MicroserviceDetails',
    component: () => import('../views/MicroserviceHealthDetails.vue'),
    props: true
  },
  {
    path: '/warehouse',
    name: 'Warehouse',
    component: () => import('../views/PIM/Warehouse.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/warehouse/:id',
    name: 'WarehouseDetails',
    component: () => import('../views/PIM/WarehouseDetails.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tt',
    name: 'TT',
    component: () => import('../views/SHIP/TT.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tt/:status',
    name: 'TTByStatus',
    component: () => import('../views/SHIP/TT.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/403',
    name: 'Unauthorized',
    component: () => import('../views/Errors/UnAuthorized.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/Errors/NotFound.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/ORM/Contacts.vue'),
  },
  {
    path: '/returns',
    name: 'Returns',
    component: () => import('../views/SHIP/Returns.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue'),
  },
  {
    path: '/inbox',
    name: "Inbox",
    component: () => import('../views/CC/InboxView.vue'),
  },
  {
    path: '/customer_service',
    name: "CS",
    component: () => import('../views/CC/CS.vue'),
  },
  {
    path: '/missing_orders',
    name: "MissingOrder",
    component: () => import('../components/MissingOrder.vue'),
  },
  {
    path: '/supplierorder_add',
    name: 'SupplierOrderAdd',
    component: () => import('../views/ORM/SupplierOrderManage.vue'),
    props: true
  },
  {
    path: '/supplierorder_edit/:id',
    name: 'SupplierOrderEdit',
    component: () => import('../views/ORM/SupplierOrderManage.vue'),
    props: true
  },
  {
    path:'/inbound/:id',
    name: 'InboundDetail',
    component: () => import('../components/InboundView.vue'),
    props: true
  },
  {
    path:'/shipper',
    name:'Shipper',
    component: () => import('../views/SHIP/Shipper.vue')
  },
  {
    path:'/invoice/:id',
    name:'Invoice',
    component: () => import('../views/ORM/InvoiceLayout.vue'),
    props: true
  },
  {
    path:'/stock-transfer',
    name:'StockTransfer',
    component: () => import('../views/PIM/StockTransfer.vue')
  },
  {
    path:'/invoice-builder',
    name:'InvoiceBuilder',
    component: () => import('../views/ORM/InvoiceBuilder.vue')
  },
  {
    path:'/invoice-layout',
    name:'InvoiceLayoutBuilder',
    component: () => import('../views/ORM/InvoiceLayoutView.vue')
  },
  {
    path:'/invoice',
    name:'InvoiceTable',
    component: () => import('../views/ORM/InvoiceTable.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = store.state.token == null ? localStorage.getItem('jwt') : store.state.token
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token === null || token === 'null') {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    }
  } if (to.matched.some(record => record.meta.tokenNotAvailable)) {
    if (token == null || token == 'null') {
      next()
    } else {
      next({
        path: '/dashboard',
        // params: { nextUrl: to.fullPath }
      })
    }
  }
  else {
    next()
  }
})
export default router
