export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren Sie sich"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie hier Ihr kostenloses Konto"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung abschließen"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radius"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme den Allgemeinen Geschäftsbedingungen der Website zu"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wurde erstellt. Bitte überprüfen Sie Ihre E-Mails, um Ihr Konto zu bestätigen."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundengruppe auswählen"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferant"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanten"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferantenbestellungen"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hersteller"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handwerker"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versanddienstleister"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentur"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrator"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld ist erforderlich"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsregistrierung"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich bei Ihrem Konto an"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Konto ist mit dieser E-Mail verknüpft"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Passwort"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsadresse"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldatum"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versanddatum"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferscheine"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versanddetails"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandart"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandarten"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versender"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versanddatum"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versenderdetails"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeleigenschaften"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferplatzierung"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpackungsentfernung"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungsort"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-Stunden-Service Mo.-Fr."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-Stunden-Service Sa."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48-Stunden-Service"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung zum Wunschtermin Mo.-Fr."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferung zum Wunschtermin Samstag"])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach-der-Arbeit-Lieferdienst"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express-Montageservice"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandmethode"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übergabepunkt"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normaler Versand"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozess"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferstatus"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakete"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versanddaten speichern"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse speichern"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandoptionen"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abholung im Depot"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track and Trace für"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandpositionen"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandauftrag erstellen"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse speichern"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Schnittstellen"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendete Schnittstellen"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Schnittstellen"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurieren"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisierung starten"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabelle"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktualisierung"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quellenwert"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statusart"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel-Erstellung"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hersteller"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retouren"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["versandt"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht versandt"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern Sie das Kolli nach dem Hinzufügen der Zuordnung"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuordnung hier speichern"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy-Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand für"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysen"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track & Trace"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendung"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendungen"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzlich hinzugefügt"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliefert"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket mit Problem"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retouren"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestern"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Woche"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Woche"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 7 Tage"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 14 Tage"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 30 Tage"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Monat"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Monat"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 3 Monate"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 6 Monate"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 12 Monate"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht vorbereitet"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurückgesandt"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferausnahme"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendungsnummer"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versanddienstleister (Referenz)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Ereignis"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Information"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzugefügt am"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Filter angewendet"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht der letzten 14 Tage"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket hinzugefügt"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-Versanddienstleister (basierend auf Volumen)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehende Bestellungen"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinn"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen heute"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen heute"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung erfolgreich"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandprotokolle"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett erstellt"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelauswahl"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelübersicht"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelname"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbeschreibung"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistische Daten"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel synchronisieren"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagen für Artikelprojektbeschreibung"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Vorlage erstellen"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage speichern"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlagenname"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelprojekt"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelprojekt hinzufügen"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt speichern"])}
    }
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Filter gesetzt"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtversendungen"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Microsoft 365 anmelden"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsübersicht"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag hinzufügen"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsname"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig ab"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderlich"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderlich"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundengruppe"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag speichern"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivstatus auswählen"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderlichkeitsstatus auswählen"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht erforderlich"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag bearbeiten"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrag aktualisieren"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsdetails"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als gelesen markieren"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr laden"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungen synchronisieren"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop auswählen"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestell-ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelldatum"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfüllungsstatus"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefer-ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefermethode"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    }
  },
  "purchase": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkauf"])},
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferant hinzufügen"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanten-ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnittstelle"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferantenname"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Lieferantenname suchen"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtlieferantenbestellungen"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum-ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drucken"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-Vorlagenkonfigurator"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert von"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert der Artikeleigenschaften"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellübersicht"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 15 Bestellungen"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeldetails"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibungen"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbündel"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett erstellen"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketten herunterladen"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versender"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandart"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgehender Status"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super-Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendung erstellen"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartend"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgehende Sendung bearbeiten"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfüllung erstellen"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleme filtern"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massenversandaktualisierung"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandübersicht"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellaktualisierung aktivieren"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ auswählen"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfüllungsstatus auswählen"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfüllt"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht erfüllt"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfolgungsstatus auswählen"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identcode"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferscheinnummer"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Referenz-ID"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung aktualisieren"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand aktualisieren"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellungserfüllung aktivieren"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung erfüllen"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht erstellt"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versandt"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumsfilter"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefiltert nach"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Währung"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtversendungen"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktübersicht"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenkategorie"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundengruppe"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal-SKU hinzufügen"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolli-Daten"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollis hinzufügen"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollis bearbeiten"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerartikel"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puffer aktualisieren"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtmenge"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Menge"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktualisiert am"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung hinzufügen"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz-ID"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferantenbestellnummer"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel auswählen"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung erstellen"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferantenadresse"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsstatus"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang erstellen"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangs-ID"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbild"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild synchronisieren"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellstatus"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferschein"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferstatus"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild hochladen"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung aktualisieren"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Bestellung aktualisieren"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung drucken"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freie Positionen"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versand nicht erforderlich"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Versandart verfügbar"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernt"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensumme"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabatt"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuern"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformationen"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferadresse"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder auswählen"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bilder auswählen"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder löschen"])},
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferantenartikelname"])},
    "articlenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferzeit"])},
    "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufspreis"])}
  },
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
  "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsstatus"])},
  "orderpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellpositionen"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellung bearbeiten"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "addPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket hinzufügen"])},
  "inbound": {
    "fulfillerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfüller-ID"])},
    "mercInboundNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händler-Eingangsnummer"])},
    "inboundDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsdatum"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "warehouseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagername"])},
    "inboundDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingangsdetails"])},
    "addPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakete hinzufügen"])}
  },
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
  "StockTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsübertrag"])},
  "createFulfillment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfüllung erstellen"])},
  "change client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AG wechseln"])}
}